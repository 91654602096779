import * as React from 'react'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckIcon from '@mui/icons-material/Check';
import AmbossNodeLink from './AmbossNodeLink';

type Props = {
  nodeId: string,
  allowCopy?: boolean,
  maxCharacters?: number,
  ambossLink?: boolean,
}

const NodePubkey = ({ nodeId, allowCopy = true, ambossLink=false, maxCharacters = 12 }: Props) => {
  const [icon, setIcon] = React.useState<"copy" | "check">("copy");

  function onCopied() {
    setIcon("check");
    setTimeout(() => {
      setIcon("copy");
    }, 3000);
  }

  const maxCharacters2 = Math.round(maxCharacters / 2);
  return (
    <React.Fragment>
      <span className="mr-1">
        {nodeId.slice(0, maxCharacters2)}...{nodeId.slice(nodeId.length - maxCharacters2)}
      </span>

      {allowCopy &&
        <React.Fragment>
          {icon === "copy" &&
            <span title="Copy pubkey">
              <CopyToClipboard text={nodeId} onCopy={onCopied}>
                <FileCopyIcon
                  fontSize="inherit" color="inherit" className="mb-1 cursor-pointer hover:text-gray-700"
                />
              </CopyToClipboard>
            </span>
          }
          {icon === 'check' &&
            <CheckIcon
              fontSize="inherit" color="inherit" className="mb-1 text-viking-700"
            />
          }
          {ambossLink &&
            <AmbossNodeLink nodeId={nodeId} />
          }
          
        </React.Fragment>
      }

    </React.Fragment>
  );
};

export default NodePubkey;