import React from 'react';
import { useInterval } from 'react-interval-hook';
import { useLnRouterUser } from '../useLnRouterUser';


export type UserPlanType = 'ANONYMOUS' | 'AUTHENTICATED' | 'SUBSCRIBED';


export class IUserSubscription {
  id: string;
  start: string;
  end: string;
}

export class UserSubscription {
  constructor(public data: IUserSubscription) { }

  get isActive(): boolean {
    const start = new Date(this.data.start);
    const end = new Date(this.data.end);
    const now = new Date();

    return start.getTime() < now.getTime() && now.getTime() < end.getTime();
  }

  get start(): Date {
    return new Date(this.data.start);
  }

  get end(): Date {
    return new Date(this.data.end);
  }
}


interface IUserPlan {
  plan: UserPlanType,
  subscription?: UserSubscription,
  refetch: () => any,
  loading: boolean
}

const _1hr = 60 * 60 * 1000;

export function useUserPlan(): IUserPlan {
  const [rerender, setRerender] = React.useState<any>({});
  const user = useLnRouterUser();
  useInterval(() => {
    // Rerender component so if the plan expires, the page doesn't show the values anymore.
    setRerender({});
  }, _1hr)

  if (user.loading) {
    return {
      plan: undefined,
      refetch: user.refetch,
      loading: user.loading,
      subscription: undefined
    }
  }

  if (!user.data) {
    return {
      plan: 'ANONYMOUS',
      refetch: user.refetch,
      loading: user.loading,
      subscription: undefined
    }
  }

  const subscriptions: UserSubscription[] = (user.data.plans || []).map(data => new UserSubscription(data));
  const activeSubs = subscriptions.filter(s => s.isActive);
  const active = activeSubs.length > 0;

  let plan: UserPlanType = 'ANONYMOUS';

  if (user.data) {
    plan = active ? 'SUBSCRIBED' : 'AUTHENTICATED';
  }

  if (active) {
    return {
      plan: plan,
      refetch: user.refetch,
      loading: user.loading,
      subscription: activeSubs[0]
    }
  } else {
    return {
      plan: plan,
      refetch: user.refetch,
      loading: user.loading
    }
  }
}