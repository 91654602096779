import { gql } from "@apollo/client";

export interface INodeInfo {
  id: string;
  label: string;
  channelCount: number;
  capacitySat: number;
  closenessCentrality?: number;
  closenessCentralityPercentile?: number;
  routingScore?: number;
  routingScorePercentile?: number;
  medianPpm: number;
  bosScore: number;
  terminalScore: number;
  terminalRank: number;
  ipAddress?: string;
  torAddress?: string;
  liquidityAd?: INodeLiquidityAd;
  liquidity: INodeLiquidity
}

export interface INodeLiquidityAd {
  leaseFeeBaseMsat: number;
  leaseFeeBasis: number;
  fundingWeight: number;
  channelFeeMaxBaseMsat: number;
  channelFeeMaxProportionalThousandths: number;
}

export interface INodeLiquidity {
  inOutboundRatio: number;
  balancedPercent: number;
  balancedPercentPercentile: number;
  avgOnionResponseTime: number;
  avgOnionResponseTimePercentile: number;
  bestThirdOnionResponseTime: number;
}


export function NodeInfoFragment(isSubscribed: boolean) {
  const subscriberFields = `
  routingScore
  routingScorePercentile
    liquidity {
      inOutboundRatio
      balancedPercent
      balancedPercentPercentile
      avgOnionResponseTime
      avgOnionResponseTimePercentile
      bestThirdOnionResponseTime
    }`;
  return `
  
  fragment NodeInfoFragment on NodeInfoOutput{
      id
      label
      channelCount
      capacitySat
      medianPpm
      closenessCentrality
      closenessCentralityPercentile
      ipAddress
      torAddress
      liquidityAd {
        leaseFeeBaseMsat
        leaseFeeBasis
        fundingWeight
        channelFeeMaxBaseMsat
        channelFeeMaxProportionalThousandths
      }
      ${isSubscribed ? subscriberFields : ''}
  }`
}