import * as React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import SimpleNodeListContext, { SimpleNode } from '../../general/SimpleNodeListContext';
import { GraphNode } from '../../probe/models/GraphNode';
import { useRouter } from 'next/router';
import SearchIcon from '@mui/icons-material/Search';
import NodePubkey from '../../general/NodePubkey';
import stringSimilarity from "string-similarity";


function itemFormat(node: SimpleNode, props: any) {
    if (!node) {
        return '';
    }
    return <div {...props}>
        <div>
            <div>{node.label}</div>
            <div className="text-xs text-gray-500">
                <NodePubkey allowCopy={false} nodeId={node.id} maxCharacters={24} />
            </div>
        </div>
    </div>
}

type Props = {
    onChange?: (node: SimpleNode | GraphNode) => void,
    size?: "normal" | "big",
    autoFocus?: boolean,
    label?: string,
    className?: string,
    style?: any
}

function getBestStringMatches(searchString: string, nodes: SimpleNode[]): SimpleNode[] {
    nodes = nodes.filter(n => n.label !== undefined);
    searchString = searchString.toLowerCase();

    // Exact pubkey match
    const pubkeyMatch = nodes.filter(n => n.id === searchString);
    if (pubkeyMatch.length > 0) {
        return pubkeyMatch;
    }

    // Substring match
    const preFilteredNodes = nodes.filter(n => (n.label || '').toLowerCase().includes(searchString));

    // Sort by string similarity
    const distances = preFilteredNodes.map(node => {
        const distance = stringSimilarity.compareTwoStrings(searchString, (node.label || ''));
        return {
            node,
            distance
        }
    });
    distances.sort((a,b) => b.distance - a.distance);
    const resultNodes = distances.map(d => d.node);

    // Return top 100
    return resultNodes.slice(0, 100);
}

const UrlTiedNodeInputField: React.FC<Props> = ({ onChange, label, size = "normal", autoFocus = false, className='', style={} }: Props) => {
    const router = useRouter();
    const nodeListContext = React.useContext(SimpleNodeListContext);
    const [isOpen, setOpen] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<SimpleNode>(null);


    function setResult(newVal: SimpleNode | string) {
        if (!newVal) {
            return;
        }
        const node = newVal as SimpleNode;
        router.push({
            pathname: `/node/${node.id}`,
        }, undefined, { shallow: true })

        if (value === null) { // Small trick to clear the input
            setValue(undefined)
        } else {
            setValue(null);
        }

        if (onChange) {
            onChange(node)
        }
    }

    function getFilteredOptions(textInput: string) {
        const options = [...(nodeListContext.nodes || [])];
        return getBestStringMatches(textInput, options);
        // return options.filter(node => {
        //     const label = node.label + ' - ' + node.id
        //     return label.toLocaleLowerCase().includes(textInput.toLocaleLowerCase());
        // }).slice(0, 100);
    }

    const isSSR = typeof window === 'undefined';
    const windowWidth = isSSR? 600: window.screen.width;
    const canExpand = windowWidth > 400;
    const componentWidth = ((isOpen && canExpand || size === 'big')) ? `w-80` : 'w-60';
    return (
        <Autocomplete
            className={componentWidth + '  ' + className}
            blurOnSelect={true}
            open={isOpen}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            popupIcon={<SearchIcon />}
            options={[...(nodeListContext.nodes || [])]}
            value={value}
            getOptionLabel={(node) => node.label + ' - ' + node.id}
            renderOption={(props, node) => {
                return itemFormat(node, props);
            }}

            filterOptions={(options, state: object) => {
                const textInput: string = (state as any).inputValue;
                return getFilteredOptions(textInput);
            }}
            noOptionsText='No nodes available'
            onChange={(event, value) => {
                setResult(value);

            }}
            renderInput={(params) => {
                // console.log('params', params);
                return <TextField
                    {...params}
                    label={label}
                    placeholder="Node alias or pubkey"
                    variant='outlined'
                    size={size === "normal" ? "small" : "medium"}
                    autoFocus={autoFocus}
                    style={style}
                    onKeyPress={e => {
                        if (e.code === 'Enter') {
                            const options = getFilteredOptions((e.target as any).value);
                            if (options.length > 0) {
                                const firstEle = options[0];
                                setOpen(false);
                                setResult(firstEle);
                            }
                        }
                    }}
                    className="bg-white no-border rounded focus:outline-none "
                />;
            }}

            isOptionEqualToValue={(option, value) => {
                return option.id === option.id;
            }}
        />
    );
}

export default UrlTiedNodeInputField;